import $ from 'jquery';
window.jQuery = $;

import 'bootstrap';
import slick from 'slick-carousel';
require('@fancyapps/fancybox/dist/jquery.fancybox.js');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');
require('baguettebox.js/dist/baguetteBox.css');
import baguetteBox from 'baguettebox.js';
require('jquery-autogrow-textarea/dist/jquery.autogrow.min');

$( document ).ready(function () {
  $('.hamburger').click(function () {
    if($(this).hasClass('active'))
      $(this).removeClass('active');
    else
      $(this).addClass('active');
  });

  baguetteBox.run('.baguette');
  $('[data-toggle="tooltip"]').tooltip();
  $("textarea.autogrow").autogrow();

  $('.slick-foods').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    arrows: true,
    fade: true,
    cssEase: 'linear',
    prevArrow: '<button type="button" class="slick-prev"><img src="/caret_left.svg" height="65"></button>',
    nextArrow: '<button type="button" class="slick-next"><img src="/caret_right.svg" height="65"></button>',
  });

});